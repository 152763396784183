import TermsOfService from './pages/terms/terms';
import Home from './pages/homePage/Home';
import { HashRouter as Router, Route } from 'react-router-dom';

const Routes = () => {
    return (
        <Router>
            <Route component={Home} path="/" exact />
            <Route component={TermsOfService} path="/privacy" />
            <Route
                path='/terms'
                render={() => (
                    <TermsOfService terms={true}/>
                )}
            />
        </Router>
    );
}

export default Routes;

